import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Container, Text, Button } from "@atoms";

const BenefitList = ({ color, heading, copy, button, benefits }) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      x: "10%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      x: "0%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <Container>
      <div
        className={classNames(
          "flex flex-wrap gap-10 md:flex-nowrap md:gap-24",
          {
            "text-white": color === "bg-black" || color === "bg-teal-dark",
          }
        )}
      >
        <div className="flex flex-grow flex-col items-start gap-8 md:flex-[0_1_calc(50%-5rem)]">
          <div className="flex flex-col gap-5">
            <Text variant="h2">{heading}</Text>
            <Text variant="body">{copy}</Text>
          </div>
          {!!button?.url && (
            <Button to={button.url} color="pink">
              {button.text}
            </Button>
          )}
        </div>
        <m.ul
          variants={container}
          initial="hidden"
          whileInView="show"
          className="flex flex-grow flex-col gap-10 md:flex-1 md:pt-1.5"
        >
          {benefits?.map(benefit => {
            const { uid, heading: subheading, descriptor, icon } = benefit;
            return (
              <m.li key={uid} variants={item} className="flex gap-5">
                {!!icon[0] && (
                  <div
                    className={classNames(
                      "svg-icon h-10 w-10 flex-shrink-0 pt-2",
                      {
                        "text-white":
                          color === "bg-black" || color === "bg-teal-dark",
                        "text-teal":
                          color !== "bg-black" && color !== "bg-teal-dark",
                      }
                    )}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: icon[0]?.code }}
                  />
                )}
                <div className="flex flex-col gap-5">
                  <Text variant="h6">{subheading}</Text>
                  <Text
                    richText
                    className={classNames("prose-p:text-sm", {
                      "prose-white":
                        color === "bg-black" || color === "bg-teal-dark",
                    })}
                  >
                    {descriptor}
                  </Text>
                </div>
              </m.li>
            );
          })}
        </m.ul>
      </div>
    </Container>
  );
};

BenefitList.defaultProps = {};

export default BenefitList;
